import { EmailAddressDetails, IConfigurations, IHoursType } from "types";
import { translationKeys as keys } from "translations/keys";
import { MessageBarType } from "@fluentui/react";
import {
  CUSTOM_HEADERS_KEYS_MAPPER,
  HOURS,
  INITIAL_CONFIG_VALUES,
  MAX_TSID_LENGTH,
} from "constants/index";

export function extractAddresses(address: string) {
  return address.includes(";")
    ? address
        .split(";")
        .map((address: string) => address.trim())
        .filter((address: string) => address)
    : [address.trim()];
}

export function parseFaxNumbers(numbers: string, sufix: string) {
  if (!numbers) return [];
  const numbersArray = extractAddresses(numbers);
  const parsedFaxNumbers = numbersArray.map((number) => number + sufix);
  return parsedFaxNumbers;
}

export const parseEmailAddresses = (emails: string) => {
  const trimmedEmails = trimInBetweenSpaces(emails);
  const emailsArray = extractAddresses(trimmedEmails);
  let fieldReceivers = "";
  emailsArray.forEach((receiver: any) => {
    if (!receiver) return;
    fieldReceivers += receiver.trim().replace(" ", "") + "; ";
  });
  return fieldReceivers;
};

function validateList(email: string) {
  let control = "list";
  const testEmail = email.trim().replace(/;/g, "1").replace(/\+/g, "");
  control = control.slice(0, testEmail.length);
  return testEmail === control;
}

export function validateOnlyNumbers(email: string) {
  const hasReceiverList = sessionStorage.getItem("receiverList");
  if (!email) {
    return true;
  }
  if (hasReceiverList) {
    const validListWord = validateList(email);
    if (validListWord) return true;
    const testEmail = email.trim().replace(/;/g, "1").replace(/\+/g, "");
    return /^[0-9]+$/.test(testEmail) || testEmail === "list";
  }
  const testEmail = email.trim().replace(/;/g, "1").replace(/\+/g, "1");
  console.log("testEmail", testEmail);
  return /^[0-9]+$/.test(testEmail);
}

export function isValidInternationalNumber(phoneNumber: string) {
  if (
    (phoneNumber[0] === "+" && phoneNumber[1] === "0") ||
    (phoneNumber[0] === "+" && phoneNumber[1] === "+")
  )
    return false;
  if (phoneNumber.length < 3) return true;
  const isValidPhoneNumber = (phoneNumber: string) => {
    // +005411909990 should not be allowed, the correct would be 005411909990 or +5411909990
    const pattern = /^(0{2}[1-9]\d*|0{0,2}[1-9]\d*)$/;
    return pattern.test(phoneNumber);
  };

  return phoneNumber[0] === "+"
    ? isValidPhoneNumber(phoneNumber.replace("+", "00"))
    : isValidPhoneNumber(phoneNumber);
}

export const checkForDuplicates = (array: any) => {
  const unique = array.filter((value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
  });
  return unique.length !== array.length;
};

export const trimInBetweenSpaces = (string: string) => {
  return string.replaceAll(" ", "");
};

export function validateEmail(name: string, emails: string) {
  let validation: string = "";
  if (!emails) return validation;

  if (emails.includes(",")) {
    return (validation = keys.useSemiColonsError);
  }
  const emailsToValidate = trimInBetweenSpaces(emails);
  const emailToValidate = extractAddresses(emailsToValidate);

  const hasDuplicates = checkForDuplicates(emailToValidate);

  if (hasDuplicates) {
    return (validation = keys.duplicateAddressesError);
  }

  for (let i = 0; i < emailToValidate.length; i++) {
    if (name === "to" || name === "cc") {
      const hasOnlyNumbers = validateOnlyNumbers(emailToValidate[i]);
      console.log("hasOnlyNumbers", hasOnlyNumbers);
      if (!hasOnlyNumbers) {
        return (validation = keys.onlyNumbersError);
      } else {
        validation = "";
      }
      const isInternationalPrefixValid = isValidInternationalNumber(
        emailToValidate[i]
      );
      if (!isInternationalPrefixValid) {
        return (validation = keys.internationalNumberError);
      } else {
        validation = "";
      }
    } else {
      if (
        !emailToValidate[i] ||
        !emailToValidate[i].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        return (validation = keys.validEmailAddressesError);
      } else {
        validation = "";
      }
    }
  }
  return validation;
}

export function validateTsid(tsid: string) {
  if (!tsid) return "";
  if (tsid.trim().length > MAX_TSID_LENGTH) {
    return keys.tsidLengthError;
  }
  return "";
}

export function setReceiverAddress(
  receivers: string,
  fieldName: "to" | "cc",
  sufix: string
) {
  const faxRecipients = parseFaxNumbers(receivers, sufix);

  Office.context.mailbox.item?.[fieldName].getAsync((resultMailbox) => {
    const emailRecipients = resultMailbox.value;
    const filteredFaxRecipients = faxRecipients.filter((faxRecipient) => {
      if (
        emailRecipients.find(
          (emailRecipient) => emailRecipient.emailAddress === faxRecipient
        )
      ) {
        return false;
      }
      return true;
    });
    const filteredEmailRecipients = emailRecipients.filter((emailRecipient) => {
      if (emailRecipient.emailAddress.includes(sufix)) {
        return faxRecipients.includes(emailRecipient.emailAddress);
      }
      return true;
    });
    Office.context.mailbox.item?.[fieldName].setAsync(
      [...filteredEmailRecipients, ...filteredFaxRecipients],
      (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          console.error(`failed to set ${fieldName}`);
          return fieldName === "to"
            ? keys.failToSetToError
            : keys.failToSetCcError;
        }
      }
    );
  });
}

export function parseReceiverFaxNumber(receivers: EmailAddressDetails[]) {
  return receivers.reduce((acc, receiver) => {
    if (!receiver.emailAddress.includes(INITIAL_CONFIG_VALUES.sufix)) {
      return acc;
    }
    return acc + receiver.emailAddress.split("@")[0] + "; ";
  }, "");
}

export const fileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export async function handleFile(event: any, fileName: string) {
  const newFile = event.target.files[0];
  if (newFile.size <= 0) {
    return {
      name: fileName,
      content: "",
      error: keys.emptyFileError,
    };
  }
  const fileExtension =
    newFile.name.split(".")[newFile.name.split(".").length - 1];
  if (newFile.type !== "text/plain" || fileExtension !== "txt") {
    return {
      name: fileName,
      content: "",
      error: keys.onlyTxtFilesError,
    };
  }

  try {
    const base64 = (await fileToBase64(newFile)) as string;
    const content = base64.split(",")[1];
    return { name: newFile.name, content: content, error: "" };
  } catch (error: any) {
    console.log("error", error.message);
    return {
      name: fileName,
      content: "",
      error: error.message,
    };
  }
}

export function parseDateAndTime(date: string, hour: string | undefined) {
  const dateToParse = new Date(date);
  const timeDifference = dateToParse.getTimezoneOffset();
  let dateWithTimeDifference;
  if (timeDifference < 0) {
    dateWithTimeDifference = new Date(
      dateToParse.setMinutes(dateToParse.getMinutes() - timeDifference)
    );
  } else {
    dateWithTimeDifference = new Date(
      dateToParse.setMinutes(dateToParse.getMinutes() + timeDifference)
    );
  }
  const dateToSend = new Date(dateWithTimeDifference)
    .toISOString()
    .split("T")[0];
  const timeZone = new Date(date).toString().split("GMT")[1].split(" ")[0];
  const dateAndHourToSend = dateToSend + "-" + hour + timeZone;
  return dateAndHourToSend;
}

export function setNotification(
  type: MessageBarType,
  message: string,
  callBack: React.Dispatch<any>
) {
  setTimeout(() => {
    return callBack({ type: type, message: "" });
  }, 5000);
  return callBack({ type: type, message: message });
}

export function processHeaderValues(e: any, headerValue?: string) {
  let key = "";
  const { name, id } = e.target as HTMLInputElement;
  let { value } = e.target as HTMLInputElement;

  if (headerValue) value = headerValue;
  if (name) {
    key =
      CUSTOM_HEADERS_KEYS_MAPPER[
        name as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER
      ];
  } else if (id) {
    key =
      CUSTOM_HEADERS_KEYS_MAPPER[id as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER];
  }
  const trimmedValue = name === "tsid" ? value : trimInBetweenSpaces(value);
  return { key, trimmedValue };
}

export function removeCustomHeadersValues(key: string, headers: any) {
  const newCustomHeadersValues = { ...headers };
  const keyToRemove =
    CUSTOM_HEADERS_KEYS_MAPPER[key as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER];
  delete newCustomHeadersValues[keyToRemove as keyof typeof headers];
  return newCustomHeadersValues;
}

export const parseDropDownOptions = (options: any, canSuppress?: boolean) => {
  const parsedOptions = options.map((option: any) => {
    return {
      key: option.id,
      text: option.displayName,
    };
  });
  if (canSuppress) parsedOptions.unshift({ key: "suppress", text: "Suppress" });
  return parsedOptions;
};

export function parseConfigurations(configurations: any): IConfigurations {
  return {
    fieldValues: {
      reportTo: {
        value: configurations.statusMailTo.value || "",
        error: "",
      },
      additionalReport: {
        value: configurations.statusMailCC.value || "",
        error: "",
      },
      tsid: {
        value: configurations.tsid.value || "",
        error: "",
      },
    },
    checkBoxValues: {
      faxCopy: configurations.sndStatusMail.value || false,
      onlyAtt: configurations.attOnly.value || false,
      addReceiverList: false,
      date: false,
      coverPage: configurations.coverPage.value ? true : false,
      costCenter: configurations.costCenter.value ? true : false,
    },
    dropdownOptions: {
      coverPage: configurations.coverPage.display
        ? parseDropDownOptions(
            configurations.coverPage.options,
            configurations.coverPage.canSuppress
          )
        : [],
      costCenter: configurations.costCenter.display
        ? parseDropDownOptions(
            configurations.costCenter.options,
            configurations.costCenter.canSuppress
          )
        : [],
    },
    dropdownSelectedValues: {
      coverPage: configurations.coverPage.value || "",
      costCenter: configurations.costCenter.value || "",
      hour: 0,
    },
    sufix: "@" + configurations.faxGatewayDomain || "@eu.rfax.net",
    priority: configurations.priority.value || "",
    resolution: configurations.resolution.value || "",
  };
}

export const processDate = (
  setNotificationText: React.Dispatch<any>,
  dateValue: string,
  availableHours: IHoursType[],
  selectedHourKey: number,
  hour?: IHoursType
) => {
  let newAvailableHours = availableHours;
  let errorMesage = "";
  const isDateToday = new Date(dateValue).getDate() === new Date().getDate();

  if (isDateToday) {
    const currentMinutes = new Date().getMinutes();
    const currentHourInMinutes = new Date().getHours() * 60 + currentMinutes;
    const selectedHourIndex =
      (hour && availableHours.indexOf(hour) + 1) ||
      availableHours.indexOf(availableHours[selectedHourKey]);
    const selectedHourInMinutes = selectedHourIndex * 30;
    const isTimeDue = selectedHourInMinutes < currentHourInMinutes + 30;
    if (isTimeDue) {
      errorMesage = keys.passedHourError;
      setNotification(
        MessageBarType.warning,
        keys.passedHourErrorNotification,
        setNotificationText
      );
    }
    newAvailableHours = availableHours.map((hour: IHoursType) => {
      return availableHours.indexOf(hour) * 30 > currentHourInMinutes
        ? hour
        : { ...hour, disabled: true };
    });
  } else {
    newAvailableHours = HOURS;
  }
  return { newAvailableHours, errorMesage };
};

export const parseQueryString = (url: string) => {
  if (!url) return {};
  const queryString = url.split("?")[1];
  const params: { [key: string]: string } = {};

  if (queryString) {
    const paramPairs = queryString.split("&");

    for (const paramPair of paramPairs) {
      const [key, value] = paramPair.split("=");
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  }
  return params;
};

export const formatDate = (dateString: string) => {
  try {
    const [year, month, day, time] = dateString.split("-");
    const [hh, mm, ss] = time.split(".");
    const date = new Date(+year, +month + 1, +day, +hh, +mm, +ss);
    return date.toLocaleString("en-US");
  } catch (e) {
    return dateString;
  }
};
