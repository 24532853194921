import { createBrowserRouter } from "react-router-dom";
import "./global.css";
import App from "./components/app/App";
import ComposeTaskPane from "./components/composeMode/ComposeTaskPane";
import ReadTaskPane from "./components/readMode/ReadTaskPane";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/compose",
    element: <ComposeTaskPane />,
  },
  {
    path: "/read",
    element: <ReadTaskPane />,
  },
]);
