import { Spinner, SpinnerSize } from "@fluentui/react";

export interface ProgressProps {
  message: string;
}

export default function Progress({ message }: ProgressProps) {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 16px)",
        justifyContent: "center",
      }}>
      <Spinner size={SpinnerSize.large} label={message} />
    </section>
  );
}
