export const translationKeys = {
  composeFax: "composeFax",
  advancedOptions: "advancedOptions",
  priority: "priority",
  urgent: "urgent",
  normal: "normal",
  resolution: "resolution",
  standard: "standard",
  fine: "fine",
  toLabel: "toLabel",
  toPlaceholder: "toPlaceholder",
  ccLabel: "ccLabel",
  ccPlaceholder: "ccPlaceholder",
  scheduleFax: "scheduleFax",
  selectHour: "selectHour",
  selectDate: "selectDate",
  sendReportTo: "sendReportTo",
  sendReportToPlaceholder: "sendReportToPlaceholder",
  sendReportToAdditional: "sendReportToAdditional",
  sendReportToAdditionalPlaceholder: "sendReportToAdditionalPlaceholder",
  copyOfFax: "copyOfFax",
  onlyAttachments: "onlyAttachments",
  addReceiverList: "addReceiverList",
  uploadButtonLabel: "uploadButtonLabel",
  resetOptionsButton: "resetOptionsButton",
  loginButton: "loginButton",
  useSemiColonsError: "useSemiColonsError",
  onlyNumbersError: "onlyNumbersError",
  internationalNumberError: "internationalNumberError",
  validEmailAddressesError: "validEmailAddressesError",
  duplicateAddressesError: "duplicateAddressesError",
  onlyTxtFilesError: "onlyTxtFilesError",
  failToSetToError: "failToSetToError",
  failToSetCcError: "failToSetCcError",
  none: "none",
  coverPageLabel: "coverPageLabel",
  coverPagePlaceholder: "coverPagePlaceholder",
  costCenterLabel: "costCenterLabel",
  costCenterPlaceholder: "costCenterPlaceholder",
  tsidLabel: "tsidLabel",
  tsidPlaceholder: "tsidPlaceholder",
  tsidLengthError: "tsidLengthError",
  faxDetails: "faxDetails",
  sender: "sender",
  senderFaxId: "senderFaxId",
  receiver: "receiver",
  receiverFaxId: "receiverFaxId",
  faxNumber: "faxNumber",
  subject: "subject",
  body: "body",
  attachments: "attachments",
  emptyFileError: "emptyFileError",
  reply: "reply",
  createNewFax: "createNewFax",
  tokenError: "tokenError",
  internalServerError: "internalServerError",
  loadingInfo: "loadingInfo",
  errorPageButtonText: "errorPageButtonText",
  errorPageWaitingButtonText: "errorPageWaitingButtonText",
  internetHeadersError: "internetHeadersError",
  attachmentsError: "attachmentsError",
  attachmentRemovalError: "attachmentRemovalError",
  noAttachmentError: "noAttachmentError",
  passedHourError: "passedHourError",
  passedHourErrorNotification: "passedHourErrorNotification",
  faxReplyText: "faxReplyText",
  countryCodeWarning: "countryCodeWarning",
  callDuration: "callDuration",
  faxResolution: "faxResolution",
  resolutionFine: "resolutionFine",
  resolutionStandard: "resolutionStandard",
  pageCount: "pageCount",
  serviceStatus: "serviceStatus",
  archivingDays: "archivingDays",
  archivingStatus: "archivindStatus",
  noFaxMessage: "noFaxMessage",
  errorReadingInternetHeaders: "errorReadingInternetHeaders",
  callStart: "callStart",
};
