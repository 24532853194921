import React, { useEffect, useState } from "react";
import { Link } from "@fluentui/react";

import classes from "./Footer.module.css";
import { parseQueryString } from "../../helpers";

const initLinks = {
  helpLink: "https://www.retarus.com/",
  helpTitle: "Help page",
  mainLink: "https://www.retarus.com/",
  mainTitle: "Visit Homepage",
}

const Footer: React.FC = () => {

  const [links, setLinks] = useState(initLinks);

  useEffect(() => {
    const params = parseQueryString(window.location.href);
    if (params.hasOwnProperty("helpLink")) {
      initLinks.helpLink = params.helpLink;
    }
    if (params.hasOwnProperty("helpTitle")) {
      initLinks.helpTitle = params.helpTitle;
    }
    if (params.hasOwnProperty("mainLink")) {
      initLinks.mainLink = params.mainLink;
    }
    if (params.hasOwnProperty("mainTitle")) {
      initLinks.mainTitle = params.mainTitle;
    }
    setLinks(() => ({ ...initLinks }));
  }, []);

  return (
    <div className={classes.footer}>
      <div className={classes.linkContainer}>
        <Link href={links.mainLink} target="_blank" className={classes.link} underline>
          <img src="https://www.retarus.com/favicon.ico" className={classes.image} alt="returus logo"/>
          <p className={classes.title}>{links.mainTitle}</p>
        </Link>
      </div>
      <div className={classes.linkContainer}>
        <Link href={links.helpLink} target="_blank" className={classes.link} underline>
          <img src="https://www.retarus.com/favicon.ico" className={classes.image} alt="returus logo"/>
          <p className={classes.title}>{links.helpTitle}</p>
        </Link>
      </div>
    </div>
  )
}

export default Footer;
