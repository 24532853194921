import { MAX_TSID_LENGTH } from "constants/index";
import { translationKeys as keys } from "./keys";

export const translations = {
  spanish: {
    [keys.composeFax]: "Crear fax",
    [keys.advancedOptions]: "Opciones avanzadas",
    [keys.priority]: "Prioridad",
    [keys.urgent]: "Urgente",
    [keys.normal]: "Normal",
    [keys.resolution]: "Resolución",
    [keys.standard]: "Estándar",
    [keys.fine]: "Alta",
    [keys.toLabel]: "Enviar fax a: (separar por punto y coma)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Cc fax para: (separar por punto y coma)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Programar un fax",
    [keys.selectHour]: "Seleccione una hora",
    [keys.selectDate]: "Seleccione una fecha",
    [keys.sendReportTo]: "Enviar informe de estado a:",
    [keys.sendReportToPlaceholder]: "john.doe@retarus.com",
    [keys.sendReportToAdditional]:
      "Enviar informe de estado a una dirección adicional:",
    [keys.sendReportToAdditionalPlaceholder]: "john.doe@retarus.com",
    [keys.copyOfFax]: "Recibir una copia del fax en el reporte",
    [keys.onlyAttachments]: "Enviar solo adjuntos",
    [keys.addReceiverList]: "Agregar lista de receptores",
    [keys.uploadButtonLabel]: "Subir lista de receptores",
    [keys.resetOptionsButton]: "Restablecer a opciones por defecto",
    [keys.loginButton]: "Iniciar sesión para más opciones",
    [keys.useSemiColonsError]:
      "Por favor, use punto y coma para separar destinatarios",
    [keys.onlyNumbersError]: "Por favor, use solo números para destinatarios",
    [keys.internationalNumberError]:
      "Por favor, verifique que el código de país sea correcto (ejemplo: +1 o 001 para EE. UU.)",
    [keys.validEmailAddressesError]:
      "Por favor, ingrese una dirección de correo electrónico válida",
    [keys.duplicateAddressesError]:
      "Por favor, elimine las direcciones de correo electrónico duplicadas",
    [keys.onlyTxtFilesError]: "Solo se permiten archivos con extensión .txt",
    [keys.failToSetToError]: "No se pudo establecer el 'para'",
    [keys.failToSetCcError]: "No se pudo establecer el 'cc'",
    [keys.none]: "Ninguno",
    [keys.coverPageLabel]: "Carátula",
    [keys.coverPagePlaceholder]: "Seleccione una carátula",
    [keys.costCenterLabel]: "Asignar centro de costos",
    [keys.costCenterPlaceholder]: "Seleccione el centro de costos",
    [keys.tsidLabel]: "ID del transmisor",
    [keys.tsidPlaceholder]: "Ingrese el ID del remitente",
    [keys.tsidLengthError]:
      "El ID del remitente debe tener " + MAX_TSID_LENGTH + " dígitos o menos",
    [keys.faxDetails]: "Detalles del fax",
    [keys.sender]: "Remitente",
    [keys.senderFaxId]: "Remitente fax ID (TSID)",
    [keys.receiver]: "Número de Reporte",
    [keys.receiverFaxId]: "Reporte fax ID (CSID)",
    [keys.faxNumber]: "Número de Remitente",
    [keys.subject]: "Asunto",
    [keys.body]: "Cuerpo",
    [keys.attachments]: "Páginas de fax",
    [keys.emptyFileError]: "El archivo no puede estar vacío",
    [keys.reply]: "Responder",
    [keys.createNewFax]: "Crear nuevo fax",
    [keys.tokenError]:
      "Error al requerir el token de autenticatión. Intente nuevamente. Si el problema persiste, contacte a su administrador.",
    [keys.internalServerError]:
      "Error interno del servidor, por favor reintente más tarde.",
    [keys.loadingInfo]: "Cargando información del usuario ...",
    [keys.errorPageButtonText]: "Reintentar",
    [keys.errorPageWaitingButtonText]: "Esperando para reintentar",
    [keys.internetHeadersError]:
      "No se ha podido procesar su selección, por favor intente nuevamente",
    [keys.attachmentsError]:
      "No se ha podido procesar su archivos, por favor intente nuevamente",
    [keys.attachmentRemovalError]:
      "No se ha podido eliminar el archivo, intente nuevamente",
    [keys.noAttachmentError]:
      "No se detectó ningún archivo adjunto para eliminar",
    [keys.passedHourError]: "La hora seleccionada ya ha pasado",
    [keys.passedHourErrorNotification]:
      "La hora seleccionada ya ha pasado, seleccione otro horario",
    [keys.faxReplyText]: "Gracias por su fax.",
    [keys.countryCodeWarning]:
      "Por favor, verifique si un código de país es necesario",
    [keys.callDuration]: "Duración de la llamada",
    [keys.callStart]: "Fecha y hora en que comenzó la llamada,",
    [keys.pageCount]: "Cantidad de páginas",
    [keys.faxResolution]: "Resolución",
    [keys.resolutionFine]: "Bien",
    [keys.resolutionStandard]: "Estándar",
    [keys.serviceStatus]: "Estado del fax",
    [keys.archivingDays]: "Días de archivo",
    [keys.archivingStatus]: "Estado de archivo",
    [keys.noFaxMessage]: "Este mensaje no es un fax",
    [keys.errorReadingInternetHeaders]:
      "Error al procesar la información, por favor intente nuevamente más tarde",
  },
  english: {
    [keys.composeFax]: "Compose fax",
    [keys.advancedOptions]: "Advanced options",
    [keys.priority]: "Priority",
    [keys.urgent]: "Urgent",
    [keys.normal]: "Normal",
    [keys.resolution]: "Resolution",
    [keys.standard]: "Standard",
    [keys.fine]: "Fine",
    [keys.toLabel]: "Send fax to: (separate by semi-colon)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Cc fax to: (separate by semi-colon)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Schedule a fax",
    [keys.selectHour]: "Select an hour",
    [keys.selectDate]: "Select a date",
    [keys.sendReportTo]: "Send status report to:",
    [keys.sendReportToPlaceholder]: "john.doe@retarus.com",
    [keys.sendReportToAdditional]: "Send status report to additional address:",
    [keys.sendReportToAdditionalPlaceholder]: "john.doe@retarus.com",
    [keys.copyOfFax]: "Receive copy of fax in status report",
    [keys.onlyAttachments]: "Fax only attachments",
    [keys.addReceiverList]: "Add receiver list",
    [keys.uploadButtonLabel]: "Upload receiver list",
    [keys.resetOptionsButton]: "Reset to Default Options",
    [keys.loginButton]: "Login for more options",
    [keys.useSemiColonsError]: "Please use semi-colons to separate recipients",
    [keys.onlyNumbersError]: "Please use only numbers for recipients",
    [keys.internationalNumberError]:
      "Please check whether a country code is needed (example: +1 or 001 for US)",
    [keys.validEmailAddressesError]: "Please enter a valid email address",
    [keys.duplicateAddressesError]: "Duplicate addresses are not allowed",
    [keys.onlyTxtFilesError]: "Only files with .txt extension are allowed",
    [keys.failToSetToError]: "Failed to set the 'to' address",
    [keys.failToSetCcError]: "Failed to set the 'cc' address",
    [keys.none]: "None",
    [keys.coverPageLabel]: "Cover page",
    [keys.coverPagePlaceholder]: "Select a cover page",
    [keys.costCenterLabel]: "Assign cost center",
    [keys.costCenterPlaceholder]: "Select a cost center",
    [keys.tsidLabel]: "Transmitting subscriber ID",
    [keys.tsidPlaceholder]: "Enter subscriber ID",
    [keys.tsidLengthError]:
      "Subscriber ID must have " + MAX_TSID_LENGTH + " digits or less",
    [keys.faxDetails]: "Fax details",
    [keys.sender]: "Sender",
    [keys.senderFaxId]: "Sender fax ID (TSID)",
    [keys.receiver]: "Receiver number",
    [keys.receiverFaxId]: "Receiver fax ID (CSID)",
    [keys.faxNumber]: "Sender number",
    [keys.subject]: "Subject",
    [keys.body]: "Body",
    [keys.attachments]: "Fax pages",
    [keys.reply]: "Reply",
    [keys.emptyFileError]: "File cannot be empty",
    [keys.createNewFax]: "Create new fax",
    [keys.tokenError]:
      "Failed to retrieve auth token. Please try again later. If the problem persists, please contact your administrator.",
    [keys.internalServerError]:
      "Internal server error, please try again later.",
    [keys.loadingInfo]: "Loading user information ...",
    [keys.errorPageButtonText]: "Retry",
    [keys.errorPageWaitingButtonText]: "Waiting to retry",
    [keys.internetHeadersError]:
      "Could not set options successfully, please try again",
    [keys.attachmentsError]: "Attachment could not be added, please try again",
    [keys.attachmentRemovalError]: "Could not remove attachment, please retry",
    [keys.noAttachmentError]: "No attachment to be removed",
    [keys.passedHourError]: "Selected hour has already passed",
    [keys.passedHourErrorNotification]:
      "Selected hour has already passed, please select another time",
    [keys.faxReplyText]: "Thank you for your fax.",
    [keys.countryCodeWarning]: "Please check whether a country code is needed.",
    [keys.callDuration]: "Call duration",
    [keys.callStart]: "Date time call started",
    [keys.pageCount]: "Page count",
    [keys.faxResolution]: "Resolution",
    [keys.resolutionFine]: "Fine",
    [keys.resolutionStandard]: "Standard",
    [keys.serviceStatus]: "Fax status",
    [keys.archivingDays]: "Archiving days",
    [keys.archivingStatus]: "Archiving status",
    [keys.noFaxMessage]: "This message is not a fax",
    [keys.errorReadingInternetHeaders]:
      "Error procesing information, please try again later.",
  },
  french: {
    [keys.priority]: "Priorité",
    [keys.urgent]: "Urgent",
    [keys.normal]: "Normal",
    [keys.toLabel]: "Envoyer le fax à: (séparer par un point-virgule)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Inclure cc: (séparer par un point-virgule)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Planifier un fax",
  },
  german: {
    [keys.priority]: "Priorität",
    [keys.urgent]: "Dringend",
    [keys.normal]: "Normal",
    [keys.toLabel]: "Fax an: (durch Semikolon trennen)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Cc einbeziehen: (durch Semikolon trennen)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Fax planen",
  },
};
