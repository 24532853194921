export const INITIAL_VALUES = {
  addressValues: {
    to: { value: "", error: "" },
    cc: { value: "", error: "" },
  },
  file: { name: "", content: "", error: "" },
  date: "",
  locale: "en-US",
};

export const INITIAL_CONFIG_VALUES = {
  fieldValues: {
    reportTo: { value: "", error: "" },
    additionalReport: { value: "", error: "" },
    tsid: { value: "", error: "" },
  },
  checkBoxValues: {
    faxCopy: false,
    onlyAtt: false,
    addReceiverList: false,
    date: false,
    coverPage: false,
    costCenter: false,
  },
  dropdownOptions: {
    coverPage: [{ key: 0, text: "" }],
    costCenter: [{ key: 0, text: "" }],
  },
  dropdownSelectedValues: {
    coverPage: "",
    costCenter: "",
    hour: 0,
  },
  sufix: "@eu.rfax.net",
  priority: "Normal",
  resolution: "Standard",
};

export const MAX_TSID_LENGTH = 20;

export const CUSTOM_HEADERS_KEYS_MAPPER = {
  priority: "X-RETARUS-Priority",
  reportTo: "X-RETARUS-M2F-REPLYTO",
  additionalReport: "X-RETARUS-M2F-REPLYCC",
  date: "X-RETARUS-M2F-DT",
  onlyAtt: "X-RETARUS-M2F-ATTONLY",
  faxCopy: "X-RETARUS-M2F-RECEIPT",
  tsid: "X-RETARUS-M2F-CSID",
  resolution: "X-RETARUS-M2F-RES",
  coverPage: "X-RETARUS-M2F-CP",
  costCenter: "X-RETARUS-M2F-BC",
  receiverList: "X-RETARUS-M2F-DL",
};

export const HOURS = [
  { key: 1, text: "00:00" },
  { key: 2, text: "00:30" },
  { key: 3, text: "01:00" },
  { key: 4, text: "01:30" },
  { key: 5, text: "02:00" },
  { key: 6, text: "02:30" },
  { key: 7, text: "03:00" },
  { key: 8, text: "03:30" },
  { key: 9, text: "04:00" },
  { key: 10, text: "04:30" },
  { key: 11, text: "05:00" },
  { key: 12, text: "05:30" },
  { key: 13, text: "06:00" },
  { key: 14, text: "06:30" },
  { key: 15, text: "07:00" },
  { key: 16, text: "07:30" },
  { key: 17, text: "08:00" },
  { key: 18, text: "08:30" },
  { key: 19, text: "09:00" },
  { key: 20, text: "09:30" },
  { key: 21, text: "10:00" },
  { key: 22, text: "10:30" },
  { key: 23, text: "11:00" },
  { key: 24, text: "11:30" },
  { key: 25, text: "12:00" },
  { key: 26, text: "12:30" },
  { key: 27, text: "13:00" },
  { key: 28, text: "13:30" },
  { key: 29, text: "14:00" },
  { key: 30, text: "14:30" },
  { key: 31, text: "15:00" },
  { key: 32, text: "15:30" },
  { key: 33, text: "16:00" },
  { key: 34, text: "16:30" },
  { key: 35, text: "17:00" },
  { key: 36, text: "17:30" },
  { key: 37, text: "18:00" },
  { key: 38, text: "18:30" },
  { key: 39, text: "19:00" },
  { key: 40, text: "19:30" },
  { key: 41, text: "20:00" },
  { key: 42, text: "20:30" },
  { key: 43, text: "21:00" },
  { key: 44, text: "21:30" },
  { key: 45, text: "22:00" },
  { key: 46, text: "22:30" },
  { key: 47, text: "23:00" },
  { key: 48, text: "23:30" },
];

export const INITIAL_INTERNET_HEADERS = {
  sender: "",
  senderFaxId: "",
  receiver: "",
  receiverFaxId: "",
  callDuration: "",
  callStart: "",
  pageCount: "",
  faxResolution: "",
  serviceStatus: "",
  archivingDays: "",
  archivingStatus: "",
};
