import axios, { AxiosError } from "axios";
import { ItokenType } from "types";
import jwtDecode from "jwt-decode";

const BASE_URL = "https://token.retarus.symfa.com";

export const getTenantConfig = async (token: string, locale: string) => {
  const body = { token: token };
  try {
    const response = await axios.post(`${BASE_URL}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        "Accept-Language": locale,
      },
    });
    return {
      configurations: response.data,
      error: "",
    };
  } catch (error) {
    console.log("error", error);
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.status === 500
            ? "internalServerError"
            : (error.response?.data!.message as string) || error.message,
      };
    }
    return {
      error:
        error instanceof Error
          ? error.message
          : "Failed to retrieve tenant configurations.",
    };
  }
};

export const getAuthToken = async (): Promise<ItokenType> => {
  const storedToken = localStorage.getItem("azureToken");
  let expiredToken = true;
  if (storedToken) {
    const tokenProp: { exp: number } = jwtDecode(storedToken as string);
    expiredToken = tokenProp.exp < Date.now() / 1000;
  }

  try {
    if (!expiredToken) return { token: storedToken as string };
    const azureToken: string = await Office.auth.getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
    });
    localStorage.setItem("azureToken", azureToken);
    return { token: azureToken };
  } catch (error: any) {
    console.log("error", error);
    return { error: "tokenError" };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getTenantConfig, getAuthToken };
