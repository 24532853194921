import {
  PrimaryButton,
  Pivot,
  PivotItem,
  Text,
  Stack,
  IStackStyles,
  IStackTokens,
  MessageBarType,
  initializeIcons,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { handleTranslation } from "translations/helper";
import { translationKeys as keys } from "translations/keys";
import Notification from "components/notifications/Notification";
import { formatDate, setNotification } from "helpers";
import Progress from "components/spinner/Progress";
import ErrorPage from "components/errorPage/errorPage";
import { INITIAL_INTERNET_HEADERS } from "constants/index";
import Footer from "components/Footer/Footer";

const stackTokens: IStackTokens = { childrenGap: 6 };
const globalStackTokens: IStackTokens = { childrenGap: 14 };
const stackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem" },
};
const backgroundStackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem", backgroundColor: "#f4f4f4" },
};
const resolutionMapped = (resolution: string) => {
  switch (resolution) {
    case "HIGH":
      return "resolutionFine";
    case "LOW":
      return "resolutionStandard";
    default:
      return "???";
  }
};

initializeIcons();

function ReadTaskPane() {
  const [sender, setSender] = useState({
    displayName: "",
    domain: "",
  });
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [locale, setLocale] = useState("en-US");
  const [notification, setNotificationText] = useState({
    type: MessageBarType.info,
    message: "",
  });
  const [internetHeaders, setInternetHeaders] = useState(
    INITIAL_INTERNET_HEADERS
  );

  const translate = useCallback(
    (key: string) => {
      return handleTranslation(key, locale);
    },
    [locale]
  );

  const createFaxReply = () => {
    Office.context.mailbox.displayNewMessageFormAsync(
      {
        toRecipients: [internetHeaders.sender + "@" + sender.domain],
        subject: "",
        htmlBody: "",
      },
      function (asyncResult: any) {
        return;
      }
    );
  };

  function getCustomHeaders() {
    Office.context.mailbox.item?.getAllInternetHeadersAsync(
      getCallbackForReadMessage
    );
    function getCallbackForReadMessage(asyncResult: any) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const sender = asyncResult.value.match(/X-SenderIsdn:.*/gim);
        const senderFaxId = asyncResult.value.match(/X-SenderFaxId:.*/gim);
        const receiver = asyncResult.value.match(/X-ReceiverIsdn:.*/gim);
        const receiverFaxId = asyncResult.value.match(/X-ReceiverFaxId:.*/gim);
        const callDuration = asyncResult.value.match(/X-CallDuration:.*/gim);
        const callStart: string = asyncResult.value.match(/X-CallStart:.*/gim);
        const pageCount = asyncResult.value.match(/X-FaxPageCount:.*/gim);
        const faxResolution: string =
          asyncResult.value.match(/X-FaxResolution:.*/gim);
        const serviceStatus: string =
          asyncResult.value.match(/X-ServiceStatus:.*/gim);
        const archivingDays: string = asyncResult.value.match(
          /X-FaxInboundArchiving-Days:.*/gim
        );
        const archivingStatus: string = asyncResult.value.match(
          /X-FaxInboundArchiving-Status:.*/gim
        );

        if (!sender) {
          setInternetHeaders(INITIAL_INTERNET_HEADERS);
          return setIsLoadingConfig(false);
        }
        setInternetHeaders({
          sender: sender ? sender[0].split(" ")[1] : null,
          senderFaxId: senderFaxId ? senderFaxId[0].split(" ")[1] : null,
          receiver: receiver ? receiver[0].split(" ")[1] : null,
          receiverFaxId: receiverFaxId ? receiverFaxId[0].split(" ")[1] : null,
          callDuration: callDuration ? callDuration[0].split(" ")[1] : "",
          callStart: callStart ? formatDate(callStart[0].split(" ")[1]) : "",
          pageCount: pageCount ? pageCount[0].split(" ")[1] : "",
          faxResolution: faxResolution
            ? resolutionMapped(faxResolution[0].split(" ")[1])
            : "",
          serviceStatus: serviceStatus ? serviceStatus[0].split(" ")[1] : "",
          archivingDays: archivingDays ? archivingDays[0].split(" ")[1] : "",
          archivingStatus: archivingStatus
            ? archivingStatus[0].split(" ")[1]
            : "",
        });
        setIsLoadingConfig(false);
      } else {
        setNotification(
          MessageBarType.error,
          keys.errorReadingInternetHeaders,
          setNotificationText
        );
        setIsLoadingConfig(false);
      }
    }
  }

  function setFaxData() {
    const item = Office.context?.mailbox?.item;
    if (item) {
      setSender({
        displayName: item.sender.displayName,
        domain: item.sender.emailAddress.split("@")[1],
      });
      setSubject(item.subject);
      if (item.attachments.length > 0) {
        const attachmentList = item.attachments.map(
          (attachment: any) => attachment.name
        );
        setAttachments(attachmentList);
      } else {
        setAttachments([]);
      }
    }
  }

  const itemChanged = () => {
    setIsLoadingConfig(true);
    getCustomHeaders();
    setFaxData();
  };

  useEffect(() => {
    setFaxData();
    const locale =
      Office.context.contentLanguage ||
      Office.context.displayLanguage ||
      "en-US";
    setLocale(locale);

    getCustomHeaders();
    Office.context.mailbox.addHandlerAsync(
      Office.EventType.ItemChanged,
      itemChanged
    );
  }, []);

  return (
    <>
      {Boolean(isLoadingConfig) && (
        <Progress message={translate(keys.loadingInfo)} />
      )}
      {Boolean(notification.message) && (
        <Notification
          type={notification.type}
          message={translate(notification.message) || notification.message}
        />
      )}
      <>
        {Boolean(!isLoadingConfig && internetHeaders.sender) && (
          <Stack
            aria-label='Menu buttons'
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "8px",
              minHeight: "100vh",
            }}>
            <Pivot>
              <PivotItem headerText={translate(keys.faxDetails)}>
                <Stack tokens={globalStackTokens} styles={stackStyles}>
                  <Stack tokens={stackTokens}>
                    <Text>
                      <b>{translate(keys.sender)}:</b> {sender.displayName}
                    </Text>
                    <Text>
                      <b>{translate(keys.faxNumber)}:</b>{" "}
                      {internetHeaders.sender}
                    </Text>
                    {internetHeaders.senderFaxId && (
                      <Text>
                        <b>{translate(keys.senderFaxId)}:</b>{" "}
                        {internetHeaders.senderFaxId}
                      </Text>
                    )}
                    {internetHeaders.receiver && (
                      <Text>
                        <b>{translate(keys.receiver)}:</b>{" "}
                        {internetHeaders.receiver}
                      </Text>
                    )}
                    {internetHeaders.receiverFaxId && (
                      <Text>
                        <b>{translate(keys.receiverFaxId)}:</b>{" "}
                        {internetHeaders.receiverFaxId}
                      </Text>
                    )}
                    <Text>
                      <b>{translate(keys.subject)}:</b>{" "}
                      {subject || translate(keys.none)}
                    </Text>
                    <Text>
                      <b>{translate(keys.attachments)}:</b>{" "}
                      {attachments.length > 0 ? (
                        <ul>
                          {attachments.map((attachment) => (
                            <li key={attachment}>{attachment}</li>
                          ))}
                        </ul>
                      ) : (
                        translate(keys.none)
                      )}
                    </Text>
                    {internetHeaders.callStart && (
                      <Text>
                        <b>{translate(keys.callStart)}:</b>{" "}
                        {internetHeaders.callStart}
                      </Text>
                    )}
                    {internetHeaders.callDuration && (
                      <Text>
                        <b>{translate(keys.callDuration)}:</b>{" "}
                        {internetHeaders.callDuration}
                      </Text>
                    )}{" "}
                    {internetHeaders.pageCount && (
                      <Text>
                        <b>{translate(keys.pageCount)}:</b>{" "}
                        {internetHeaders.pageCount}
                      </Text>
                    )}
                    {internetHeaders.faxResolution && (
                      <Text>
                        <b>{translate(keys.faxResolution)}:</b>{" "}
                        {translate(internetHeaders.faxResolution)}
                      </Text>
                    )}
                    {internetHeaders.serviceStatus && (
                      <Text>
                        <b>{translate(keys.serviceStatus)}:</b>{" "}
                        {internetHeaders.serviceStatus}
                      </Text>
                    )}
                    {internetHeaders.archivingDays && (
                      <Text>
                        <b>{translate(keys.archivingDays)}:</b>{" "}
                        {internetHeaders.archivingDays}
                      </Text>
                    )}
                    {internetHeaders.archivingStatus && (
                      <Text>
                        <b>{translate(keys.archivingStatus)}:</b>{" "}
                        {internetHeaders.archivingStatus}
                      </Text>
                    )}
                  </Stack>
                  <Stack tokens={stackTokens} styles={backgroundStackStyles}>
                    <PrimaryButton
                      onClick={createFaxReply}
                      style={{ minWidth: 150 }}>
                      {translate(keys.reply)}
                    </PrimaryButton>
                  </Stack>
                </Stack>
              </PivotItem>
            </Pivot>
            <Footer />
          </Stack>
        )}
        {Boolean(!isLoadingConfig && !internetHeaders.sender) && (
          <ErrorPage
            message={translate(keys.noFaxMessage)}
            buttonText={translate(keys.errorPageButtonText)}
            waitingButtonText={translate(keys.errorPageWaitingButtonText)}
          />
        )}
      </>
    </>
  );
}

export default ReadTaskPane;
